export function Footer() {
  return (
    <>
      <section className="flex flex-col items-center pb-6 bg-white max-md:items-start border-t-8 border-[#b23437]">
        <div className="flex justify-center gap-10 ml-[-100px] py-10 text-sm max-w-6xl max-md:flex-col max-md:max-w-[90%] max-md:ml-6 max-[950px]:max-w-[900px]">
          <div>
            <h5>A NOSSA CASA</h5>
            <br />
            <p>
              Somos uma imobiliária especializada em imóveis à venda em Torres.
              Possuímos uma grande lista de imóveis de alto padrão em Torres.
              Apartamentos, casas, terrenos e lojas à venda em Torres você
              encontra na Nossa Casa.
            </p>
            <br />
            <h6>CRECI 22.262-J</h6>
          </div>
          <div>
            <h5>OBSERVAÇÕES</h5>
            <br />
            <p>
              Os dados fornecidos podem conter algumas imprecisões, assim como
              os valores podem ser alterados a qualquer momento a critério dos
              respectivos proprietários. Valores de Condomínio e IPTU são apenas
              referências e podem sofrer alterações.
            </p>
            <br />
            <span>
              <b>NOSSA CASA IMÓVEIS - TODOS OS DIREITOS RESERVADOS</b>
            </span>
            <br />
            <br />
            <span>
              Desenvolvido por{" "}
              <b>
                <a
                  style={{ color: "#b23437" }}
                  href="https://agenciacapri.com/"
                >
                  Agência Capri
                </a>
              </b>
            </span>
          </div>
          <div>
            <h5>ENDEREÇO</h5>
            <br />
            <p className="font-semibold">
              <a
                href="https://maps.app.goo.gl/fvoK5UqvJb2JsbKr5"
                target="_blank"
                className="text-[#1f1f21] no-underline hover:no-underline"
              >
                Av. Beira Mar, 1100
                <br />
                Praia Grande - Torres, RS
              </a>
              <br />
              <br />
              <h5 className="font-bold">CONTATO</h5>
              <br />
              <a
                href="tel:+555136642000"
                target="_blank"
                className="text-[#1f1f21] no-underline hover:no-underline"
              >
                (51) 3664-2000
              </a>
              <br />
              <br />
              <p
                className="cursor-pointer text-[#1f1f21] no-underline hover:no-underline"
                onClick={() => {
                  document
                    .getElementById("rd-floating_button-lfv54mcx")
                    ?.click();
                }}
              >
                (51) 9 9759-2001
              </p>
              <br />
              <br />
              <a
                href="mailto:torres@nossacasa.com.br"
                target="_blank"
                className="text-[#1f1f21] no-underline hover:no-underline"
              >
                torres@nossacasa.com.br
              </a>
            </p>
          </div>
        </div>
        <div className="text-sm text-center max-md:mx-4"></div>
      </section>
    </>
  );
}
