import { z } from "zod";

const errorMessages = {
  required: "Este campo é obrigatório",
  name: "Nome inválido",
  phone: "Número de telefone inválido",
  email: "Email inválido",
  contactWay: "Campo obrigatório",
  adress: "Endereço inválido",
};

const name = z
  .string({ required_error: errorMessages.required })
  .min(2, errorMessages.name);

const phone = z
  .string({
    invalid_type_error: errorMessages.phone,
    required_error: errorMessages.required,
  })
  .min(8, errorMessages.phone);

const email = z
  .string({ required_error: errorMessages.required })
  .regex(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g, errorMessages.email);

const contactWay = z.string();

const address = z.string().min(2, errorMessages.adress);

const addressNumber = z.string().min(2, errorMessages.adress);

const city = z.string().min(2, errorMessages.adress);

const state = z.string().min(2, errorMessages.adress);

const complement = z.string();

const propertie_address = z.string().min(2, errorMessages.adress);

const propertie_addressNumber = z.string().min(2, errorMessages.adress);

const propertie_city = z.string().min(2, errorMessages.adress);

const propertie_state = z.string().min(2, errorMessages.adress);

const propertie_complement = z.string();

export const contactFormSchema = z.object({
  name: name,
  phone: phone,
  email: email,
  contactWay: contactWay,
});

export const newsletterFormSchema = z.object({
  name: name,
  email: email,
});

export const sellContactFormSchema = z.object({
  name: name,
  phone: phone,
  email: email,
  address: address,
  number: addressNumber,
  city: city,
  state: state,
  complement: complement,
  propertie_address: propertie_address,
  propertie_addressNumber: propertie_addressNumber,
  propertie_city: propertie_city,
  propertie_state: propertie_state,
  propertie_complement: propertie_complement,
});
