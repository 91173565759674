import quemSomos from "../imgs/quem-somos.webp";
import quemSomosMobile from "../imgs/quem-somos-mobile.png";
import nossaHistoria from "../imgs/nossa-historia.webp";
import nossaHistoriaMembros from "../imgs/nossa-historia-membros.webp";
import {
  FaWhatsapp,
  FaEnvelope,
  FaPhone,
  FaLocationDot,
} from "react-icons/fa6";
import { Input } from "rsuite";
import { Link } from "react-router-dom";
import btnStyle from "../btnStyle.module.css";
import GoogleMapReact from "google-map-react";
import mapStyleJson from "../mapStyle.json";
import { RiMapPin2Fill } from "react-icons/ri";
import { useEffect, useState } from "react";
import { useZorm } from "react-zorm";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { contactFormSchema } from "../contact.schema";
import { Helmet } from "react-helmet";

export function About() {
  const mapStyle = mapStyleJson;

  const mapWidth =
    window.innerWidth > 768 ? { width: "65%" } : { width: "90%" };

  const [emailLoading, setEmailLoading] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://d335luupugsy2.cloudfront.net/js/loader-scripts/98531bad-3c6c-46fc-ab38-0de641c99e27-loader.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  useEffect(() => emailjs.init("6KUX_UqfosafJ4VF5"), []);

  const zorm = useZorm(`/quem-somos`, contactFormSchema, {
    async onValidSubmit(event) {
      event.preventDefault();
      const sendData = {
        from_name: event.data.name,
        from_email: event.data.email,
        phone: event.data.phone,
        contact: event.data.contactWay,
        code: "Quem Somos",
      };

      setEmailLoading(true);

      try {
        emailjs.send("service_a75p5lo", "template_ctfa7zf", sendData).then(
          (result) => {
            toast.success("Email enviado com sucesso!");
            setEmailLoading(false);
          },
          (error) => {
            toast.error("Tente novamente em instantes...");
            setEmailLoading(false);
          }
        );
      } catch (e) {
        console.log(e);
      }
      return event.target.reset();
      //email rd-station
      // alert("lead enviado com sucesso!");
      // console.log("send contact");
    },
  });

  let contactDisabled = zorm.validation?.success === false;

  return (
    <>
      {/* <Helmet>
        <meta name="description" content="Quem somos" />
      </Helmet> */}
      <section className="mb-14 mt-28 max-md:mb-0 max-md:mt-20">
        <div className=" max-md:h-auto max-md:min-h-0">
          <img
            className="object-cover w-full xl:h-[30rem] min-[2200px]:h-[40rem] object-bottom max-md:hidden"
            src={quemSomos}
          />
          <img className="hidden max-md:block" src={quemSomosMobile} />
        </div>
      </section>
      <section className="flex items-center justify-center mx-[20%] text-[#393939]  max-md:flex-col max-md:mt-4 max-md:mx-[10%] min-[2200px]:mx-[30%]">
        <div className="space-y-2 mr-14 max-md:mr-0">
          <h1 className="pb-4 mr-10 border-b-2 max-md:mt-0">Nossa história</h1>
          <p className="pt-2 text-base font-medium">
            Somos uma empresa focada em vendas de imóveis em Torres/RS.
            Profissionais qualificados, que unem experiência e know-how no
            mercado imobiliário de Torres. <br />
            <br />
            Desde 2003, muitas parcerias foram feitas com construtoras,
            incorporadoras e escritórios de arquitetura. A imobiliária ajudou a
            desenvolver dezenas de empreendimentos e como resultado de tudo
            isso, muitas famílias conquistaram o lar dos seus sonhos na mais
            bela praia gaúcha.
          </p>
        </div>
        <div className="max-md:mt-8">
          <img
            className="max-w-sm rounded-md shadow-xl mr-11 max-md:max-w-xs max-md:mr-0"
            src={nossaHistoria}
          />
        </div>
      </section>
      <section className="flex items-center justify-center mx-[20%] my-8 text-[#393939] max-md:mt-8 max-md:mx-[10%] min-[2200px]:mx-[30%]">
        <p className="text-base font-medium">
          Oferecemos um atendimento exclusivo, com fotos e vídeos profissionais
          dos empreendimentos, uma equipe de gerência de vendas e de marketing,
          além de um departamento jurídico exclusivo e uma consultoria
          imobiliária em cada negócio. <br /> <br /> Como reconhecimento,
          durante esse tempo de história,{" "}
          <b>a Nossa Casa foi premiada 13 vezes</b>, pela Câmara de Dirigentes
          Lojistas de Torres, com o título Mérito Lojista, por ser a imobiliária
          mais lembrada entre os clientes.
        </p>
      </section>
      <section className="flex items-center justify-center mx-[20%] text-[#393939] max-md:flex-col max-md:mt-8 max-md:mx-[10%] min-[2200px]:mx-[30%]">
        <div>
          <img
            className="max-w-xl rounded-md shadow-lg max-md:max-w-xs"
            src={nossaHistoriaMembros}
          />
        </div>
        <div className="mx-14 max-md:mx-1 max-md:mt-8">
          <p className="text-2xl font-medium text-[#b23437]   max-md:text-xl">
            Com <b>20 anos de mercado,</b> a Nossa Casa trabalha diariamente
            para continuar oferecendo o melhor atendimento e a assessoria
            imobiliária mais completa do litoral gaúcho.
          </p>
        </div>
      </section>
      <section className="flex items-center justify-center mx-[20%] mt-8 mb-10 text-[#393939] max-md:mt-8 max-md:mx-[10%] min-[2200px]:mx-[30%]">
        <p className="text-base font-medium ">
          A Nossa Casa está sempre em busca de inovar e se aprimorar. E, em
          2021, para continuar acompanhando o mercado, junto com os
          co-fundadores Benito e Jeferson - profissionais experientes que são
          referência no mercado imobiliário, novos sócios vieram para somar -
          Eduardo, Mateus e Renato. Corretores de alta performance que começaram
          suas carreiras aqui na imobiliária e que amam a profissão.
        </p>
      </section>
      <section className="flex flex-col items-start pt-20 text-lg justify-evenly pb-36 image-about ml-[-10px]">
        <div className="mx-[20%] flex items-center max-md:flex-col max-md:mx-[10%] min-[2200px]:mx-[30%]">
          <div>
            <h6 className="px-3 py-2 ml-20 mr-10 text-2xl text-white border-2 border-white border-solid max-h-24 rounded-2xl max-md:m-0">
              Missão
            </h6>
          </div>
          <div className="my-12 text-white max-md:pl-4">
            <ul className="font-medium list-disc">
              <li>
                Fornecer soluções imobiliárias através de produtos e serviços,
                que surpreendam as expectativas dos clientes, de forma segura e
                rentável.
              </li>
            </ul>
          </div>
        </div>
        <div className="mx-[20%] flex items-center max-md:flex-col max-md:mx-[10%] min-[2200px]:mx-[30%]">
          <div>
            <h6 className="px-5 py-2 ml-20 mr-10 text-2xl text-white border-2 border-white border-solid max-h-24 rounded-2xl max-md:m-0">
              Visão
            </h6>
          </div>
          <div className="mt-2 text-white max-md:pl-4">
            <ul className="font-medium list-disc">
              <li>
                Ser referência em gestão de negócios imobiliários na região sul
                do Brasil;
              </li>
              <li>
                Ser reconhecida por nossos clientes como: Sinônimo de eficiência
                e eficácia, segurança, tradição, excelência, lucratividade,
                ética e transparência.
              </li>
            </ul>
          </div>
        </div>
        <div className="mx-[20%] flex items-center max-md:flex-col max-md:mx-[10%] min-[2200px]:mx-[30%]">
          <div>
            <h6 className="px-2 py-2 ml-20 mr-10 text-2xl text-white border-2 border-white border-solid max-h-24 rounded-2xl max-md:m-0">
              Valores
            </h6>
          </div>
          <div className="my-10 text-white max-md:pl-4">
            <ul className="font-medium list-disc">
              <li>
                Respeito, ética, responsabilidade, comprometimento,
                transparência e atitude;
              </li>
              <li>Responsabilidade social e cultural;</li>
              <li>Qualidade de vida no trabalho e pelo trabalho;</li>
              <li>
                Desenvolvimento de pessoas na formação de novos profissionais de
                alta performance;
              </li>
              <li>
                Incentivo no desenvolvimento do capital intelectual de nossos
                colaboradores;
              </li>
              <li>
                Liderança, empreendedorismo, inovação, estratégia, negociação e
                foco no resultado.
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="flex flex-col items-center mt-[-100px] mb-28 2xl:mx-[15%]">
        {/* <div className="z-0 text-white shadow-xl px-96 py-44 rounded-3xl"> */}
        <div
          style={{
            height: "40vh",
            width: mapWidth.width,
            marginBottom: -45,
            borderRadius: 15,
            overflow: "hidden",
            zIndex: 1,
          }}
          className="shadow-xl"
        >
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyCU6Z8Q0FQ5FPhJWonDtcRUdFKqbVWgX7U",
              language: "pt-br",
            }}
            center={{
              lat: -29.33632402424402,
              lng: -49.72251803435718,
            }}
            zoom={16}
            options={{
              styles: mapStyle,
              clickableIcons: false,
              gestureHandling: "none",
            }}
          >
            <div className="translate-x-[-25px] translate-y-[-40px] font-thin">
              <RiMapPin2Fill size={40} color="#b23437" className="" />
              <p className="ml-[20px]">Nossa Casa Imóveis</p>
            </div>
          </GoogleMapReact>
        </div>
        {/* </div> */}
      </section>
      <section className="flex items-center justify-center my-16 max-md:flex-col max-md:items-start max-md:mx-8">
        <div className="flex flex-col max-w-sm mx-32 max-md:mx-4">
          <h3 className="text-[#b23437]">Fale com a gente</h3>
          <span className="flex items-center pt-4 text-base font-semibold text-[#3F3F41]">
            <FaLocationDot size={30} className="mr-2" />
            Av. Beira Mar, 1100 - <br />
            Praia Grande - Torres, RS
          </span>
          <span className="flex items-center pt-4 text-base font-semibold text-[#3F3F41] ">
            <FaPhone size={30} className="mr-2" />
            (51) 3664-2000
          </span>
          <span className="flex items-center pt-4 text-base font-semibold text-[#3F3F41] ">
            <FaWhatsapp size={30} className="mr-2" /> (51) 9759-2001 Plantão
          </span>
          <span className="flex items-center pt-4 text-base font-semibold text-[#3F3F41] ">
            <FaEnvelope size={30} className="mr-2" /> torres@nossacasa.com.br
          </span>
        </div>
        <div className="bg-white shadow-xl rounded-2xl max-md:mt-10">
          <h4 className="text-center text-[#3F3F41]">Deixe seu contato</h4>
          {/* <form className="flex flex-col px-20 pt-10 gap-y-2 max-md:px-10">
            
            <Input placeholder="Nome Completo" />
            <Input placeholder="Telefone" />
            <Input placeholder="Email" />
            <Input
              placeholder="Mensagem"
              className="h-32 resize-none"
              as="textarea"
            />

            <div className="flex justify-center pb-10">
              <Link to="/imovel">
                <button
                  className={`${btnStyle.button2} w-fit px-28 py-2 bg-[#A81F24] font-semibold text-base rounded-lg`}
                >
                  Enviar
                </button>
              </Link>
            </div>
          </form> */}
          <form
            className="flex flex-col px-20 pt-10 gap-y-2 max-md:px-10"
            ref={zorm.ref}
          >
            <Input
              placeholder="Nome Completo"
              id="nome"
              type="text"
              name={zorm.fields.name()}
            />
            {zorm.errors.name((error) => {
              return (
                <span className="px-1 text-sm text-[#A81F24] max-md:text-center">
                  {error.message}
                </span>
              );
            })}
            <Input
              placeholder="Telefone"
              id="phone"
              name={zorm.fields.phone()}
              type="number"
              pattern="[0-9]*"
            />
            {zorm.errors.phone((error) => {
              return (
                <span className="px-1 text-sm text-[#A81F24] max-md:text-center">
                  {error.message}
                </span>
              );
            })}
            <Input
              placeholder="Email"
              id="email"
              type="email"
              name={zorm.fields.email()}
            />
            {zorm.errors.email((error) => {
              return (
                <span className="px-1 text-sm text-[#A81F24] max-md:text-center">
                  {error.message}
                </span>
              );
            })}
            <Input
              placeholder="Forma de Contato"
              id="contact"
              name={zorm.fields.contactWay()}
            />

            <div className="flex justify-center pb-10">
              <button
                disabled={contactDisabled}
                className={`${
                  btnStyle.button2
                } w-fit px-28 py-2 bg-[#A81F24] font-semibold text-base rounded-lg ${
                  emailLoading ? "cursor-wait" : ""
                }`}
                type="submit"
              >
                Enviar
              </button>
            </div>
          </form>
        </div>
      </section>
      <ToastContainer />
    </>
  );
}
