import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { AppBar } from "./components/AppBar";
import { Home } from "./screens/Home";
import { About } from "./screens/About";
import { Buy } from "./screens/Buy";
import { Footer } from "./components/Footer";
import { Properties } from "./screens/Propertie";
import ScrollToTop from "./components/ScrollToTop";
import { Sell } from "./screens/Sell";
import { ErrorPage } from "./screens/404";
import { Launch } from "./screens/Launch";
import { Enterprise } from "./screens/Enterprise";

function App() {
  return (
    <>
      <BrowserRouter>
        <AppBar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/quem-somos" element={<About />} />

          <Route path="/imovel" element={<Buy />} />

          <Route path="/imovel/:id" element={<Properties />} />

          <Route path="/vender" element={<Sell />} />

          <Route path="/lancamentos" element={<Launch />} />

          <Route path="/lancamentos/:id" element={<Enterprise />} />

          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </>
  );
}

export default App;
