import { Dispatch, SetStateAction, useState } from "react";
import {
  Checkbox,
  CheckboxGroup,
  Input,
  InputGroup,
  InputNumber,
  Modal,
  Placeholder,
  RadioTile,
  RadioTileGroup,
  RangeSlider,
  TagPicker,
  Toggle,
  Tooltip,
  Whisper,
} from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { BiCheck } from "react-icons/bi";
import btnStyle from "../btnStyle.module.css";
import { Link } from "react-router-dom";
import {
  isInArray,
  neighborhoodFilter,
  removeItem,
  subtypesFilter,
} from "./FilterBar";

interface Filter {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  setReload: Dispatch<SetStateAction<boolean>>;
  setSearchText: Dispatch<SetStateAction<string | null>>;
  setSubTypeList: Dispatch<SetStateAction<string[]>>;
  setNeighborhoodList: Dispatch<SetStateAction<string[]>>;
  setStatusList: Dispatch<SetStateAction<string[]>>;
  setBedroomsList: Dispatch<SetStateAction<string[]>>;
  setBathroomsList: Dispatch<SetStateAction<string[]>>;
  setVideoFilter: Dispatch<SetStateAction<boolean>>;
  setExclusiveFilter: Dispatch<SetStateAction<boolean>>;
  setGaragesList: Dispatch<SetStateAction<string[]>>;
  setMinPrice: Dispatch<SetStateAction<(string | null)[]>>;
  setMaxPrice: Dispatch<SetStateAction<(string | null)[]>>;
  setMinArea: Dispatch<SetStateAction<(string | null)[]>>;
  setMaxArea: Dispatch<SetStateAction<(string | null)[]>>;
  setOrderBy: Dispatch<SetStateAction<number>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  currentPage: number;
  orderBy: number;
  maxAreaList: (string | null)[];
  minAreaList: (string | null)[];
  maxPriceList: (string | null)[];
  minPriceList: (string | null)[];
  garagesList: string[];
  exclusiveFilter: boolean;
  videoFilter: boolean;
  reload: boolean;
  searchText: string | null;
  subTypeList: string[];
  neighborhoodList: string[];
  statusList: string[];
  bedroomsList: string[];
  bathroomsList: string[];
}

const styleRadioTile = {
  paddingLeft: "8px",
  paddingRight: "14px",
  fontSize: "medium",
};

const bedroomsBathroomsValue = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5+", value: "5" },
];

export function Filter({
  open,
  reload,
  searchText,
  subTypeList,
  neighborhoodList,
  statusList,
  bedroomsList,
  bathroomsList,
  videoFilter,
  exclusiveFilter,
  garagesList,
  minPriceList,
  maxPriceList,
  minAreaList,
  maxAreaList,
  orderBy,
  currentPage,
  setCurrentPage,
  setOrderBy,
  setMinArea,
  setMaxArea,
  setMaxPrice,
  setMinPrice,
  setGaragesList,
  setExclusiveFilter,
  setVideoFilter,
  onClose,
  setReload,
  setNeighborhoodList,
  setSearchText,
  setStatusList,
  setSubTypeList,
  setBedroomsList,
  setBathroomsList,
}: Filter) {
  const [rangeSliderValue, setRangeSliderValue] = useState([0, 0]);

  //params
  const [filterNeighborhoodList, setFilterNeighborhoodList] =
    useState(neighborhoodList);

  const [filterSubTypeList, setFilterSubTypeList] = useState(subTypeList);

  const [filterStatusList, setFilterStatusList] = useState(statusList);

  const [filterBedroomsList, setFilterBedroomsList] = useState(bedroomsList);

  const [filterBathroomsList, setFilterBathroomsList] = useState(bathroomsList);

  const [filterGaragesList, setFilterGaragesList] = useState(garagesList);

  const [filterWithVideoFilter, setFilterWithVideoFilter] =
    useState(videoFilter);

  const [filterExclusiveFilter, setFilterExclusiveFilter] =
    useState(exclusiveFilter);

  const [filterMinPrice, setFilterMinPrice] = useState(minPriceList);

  const [filterMaxPrice, setFilterMaxPrice] = useState(maxPriceList);

  const [filterMinArea, setFilterMinArea] = useState(minAreaList);

  const [filterMaxArea, setFilterMaxArea] = useState(maxAreaList);

  return (
    <>
      <Modal
        backdrop={true}
        open={open}
        size="md"
        onClose={() => onClose(false)}
        onExit={() => {
          setNeighborhoodList(filterNeighborhoodList);
          setSubTypeList(filterSubTypeList);
          setStatusList(filterStatusList);
          setBedroomsList(filterBedroomsList);
          setBathroomsList(filterBathroomsList);
          setGaragesList(filterGaragesList);
          setVideoFilter(filterWithVideoFilter);
          setExclusiveFilter(filterExclusiveFilter);
          setMinPrice(filterMinPrice);
          setMaxPrice(filterMaxPrice);
          setMinArea(filterMinArea);
          setMaxArea(filterMaxArea);
          reload ? setReload(false) : setReload(true);
        }}
        className="mt-[-30px] max-md:mt-6 touch-none"
      >
        <Modal.Header className="border-b-2">
          <Modal.Title>
            <h3 className="flex items-center justify-center pb-4 text-2xl font-normal">
              Filtros
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[66vh] touch-pan-y overflow-x-hidden">
          <div className="p-5 border-b-2 max-md:p-2 max-md:mr-7">
            <h4 className="font-semibold">Tipo de propriedade</h4>
            <br />

            <TagPicker
              searchable={false}
              data={subtypesFilter.map((item) => {
                return { label: item, value: item };
              })}
              block
              placeholder={`Tipos de Propriedade`}
              menuClassName="typesProps"
              onChange={(item) => {
                setFilterSubTypeList(item);
              }}
              cleanable
              defaultValue={filterSubTypeList}
              className="max-w-[99%]"
            />
            <br />
          </div>
          <div className="p-5 border-b-2 max-md:px-2 max-md:py-4 max-md:mr-7">
            <h4 className="font-semibold">Faixa do Preço</h4>
            <div className="pt-2">
              <div className="flex items-start ">
                <div className="flex items-center m-2 max-md:m-0 max-md:mr-4">
                  <div className="text-lg ">
                    <span>Mínimo</span>
                    <br />
                    <div className="flex items-center justify-start">
                      {/* <InputNumber
                        prefix="R$"
                        postfix=".000,00"
                        type="number"
                        id="priceMin"
                        className="flex mt-2 outline-none"
                        size="md"
                        step={25}
                        value={rangeSliderValue[0]}
                        onChange={(nextValue: any) => {
                          const [start, end] = rangeSliderValue;
                          if (nextValue < end) {
                            return;
                          }
                          setRangeSliderValue([nextValue, end]);
                          setFilterMinPrice(["minPrice", nextValue]);
                        }}
                      /> */}
                      <Whisper
                        trigger="focus"
                        speaker={<Tooltip>Valor Mínimo</Tooltip>}
                        placement="bottom"
                      >
                        <InputGroup
                          inside
                          style={{
                            width: 160,
                            marginBottom: 10,
                          }}
                        >
                          <InputGroup.Addon>R$</InputGroup.Addon>
                          <Input
                            placeholder="0"
                            type="number"
                            enterKeyHint="done"
                            step={50}
                            value={
                              filterMinPrice[1] === "0"
                                ? undefined
                                : filterMinPrice[1]?.toString()
                            }
                            onChange={(nextValue: any) => {
                              setFilterMinPrice(["minPrice", nextValue]);
                            }}
                          />
                        </InputGroup>
                      </Whisper>
                    </div>
                  </div>
                </div>

                <div className="flex px-4 m-2 max-md:m-0">
                  <div className="text-lg">
                    <span>Máximo</span>

                    <br />
                    <div className="flex items-center justify-start">
                      {/* <InputNumber
                        prefix="R$"
                        postfix=".000,00"
                        type="number"
                        id="priceMin"
                        className="flex mt-2 outline-none"
                        size="md"
                        step={25}
                        value={rangeSliderValue[0]}
                        onChange={(nextValue: any) => {
                          const [start, end] = rangeSliderValue;
                          if (nextValue < end) {
                            return;
                          }
                          setRangeSliderValue([nextValue, end]);
                          setFilterMinPrice(["minPrice", nextValue]);
                        }}
                      /> */}
                      <Whisper
                        trigger="focus"
                        speaker={<Tooltip>Valor Máximo</Tooltip>}
                        placement="bottom"
                      >
                        <InputGroup
                          inside
                          style={{
                            width: 160,
                            marginBottom: 10,
                          }}
                        >
                          <InputGroup.Addon>R$</InputGroup.Addon>
                          <Input
                            placeholder="0"
                            type="number"
                            enterKeyHint="done"
                            step={50}
                            value={
                              filterMaxPrice[1] === "0"
                                ? undefined
                                : filterMaxPrice[1]?.toString()
                            }
                            onChange={(nextValue: any) => {
                              setFilterMaxPrice(["maxPrice", nextValue]);
                            }}
                          />
                        </InputGroup>
                      </Whisper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="p-5 border-b-2 max-md:p-2 max-md:mr-7">
            <h4 className="font-semibold">Faixa do Preço</h4>
            <div className="pt-10">
              <div className="px-2">
                <RangeSlider
                  value={[rangeSliderValue[0], rangeSliderValue[1]]}
                  min={300}
                  max={1500}
                  graduated
                  step={100}
                  tooltip={false}
                  onChange={(value) => {
                    setRangeSliderValue(value);
                    setFilterMinPrice([
                      "minPrice",
                      String(rangeSliderValue[0] + "000"),
                    ]);
                    setFilterMaxPrice([
                      "maxPrice",
                      String(rangeSliderValue[1] + "000"),
                    ]);
                  }}
                />
              </div>

              <div className="flex items-center justify-evenly">
                <div className="flex px-4 m-2 max-md:px-0 max-md:m-0">
                  <label className="text-lg">
                    Preço Mínimo
                    <br />
                    <div className="flex items-center justify-start">
                      <InputNumber
                        prefix="R$"
                        postfix=".000,00"
                        type="number"
                        id="priceMin"
                        className="flex mt-2 outline-none"
                        size="md"
                        step={25}
                        value={rangeSliderValue[0]}
                        onChange={(nextValue: any) => {
                          const [start, end] = rangeSliderValue;
                          if (nextValue < end) {
                            return;
                          }
                          setRangeSliderValue([nextValue, end]);
                          setFilterMinPrice(["minPrice", nextValue]);
                        }}
                      />
                    </div>
                  </label>
                </div>

                <div className="flex px-4 m-2 max-md:px-0 max-md:m-0">
                  <label className="text-lg">
                    Preço Máximo
                    <br />
                    <div className="flex items-center">
                      <InputNumber
                        prefix="R$"
                        postfix=".000,00"
                        type="number"
                        id="priceMax"
                        className="mt-2 outline-none"
                        size="md"
                        step={25}
                        value={rangeSliderValue[1]}
                        onChange={(nextValue: any) => {
                          const [start, end] = rangeSliderValue;
                          if (nextValue < start) {
                            return;
                          }
                          setRangeSliderValue([start, nextValue]);
                          setFilterMaxPrice(["maxPrice", nextValue]);
                        }}
                      />
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div> */}
          <div className="p-5 border-b-2 max-md:px-2 max-md:py-4 max-md:mr-7">
            <h4 className="font-semibold">Dormitórios e banheiro</h4>
            <br />
            <h6 className="pb-2 font-normal">Dormitórios</h6>

            <RadioTileGroup
              className="flex-row items-center pl-2 scale-[1.1] gap-0 max-md:scale-[1.17]"
              // defaultValue={filterBedroomsList[1] ? filterBedroomsList[1] : ""}
            >
              {bedroomsBathroomsValue.map((item) => {
                return (
                  <Checkbox
                    defaultChecked={isInArray(item.value, filterBedroomsList)}
                    onChange={() => {
                      filterBedroomsList.includes(`${item.value}`)
                        ? removeItem(filterBedroomsList, `${item.value}`)
                        : setFilterBedroomsList([
                            ...filterBedroomsList,
                            `${item.value}`,
                          ]);
                    }}
                    style={styleRadioTile}
                    className="max-md:overflow-visible max-md:px-2"
                    value={item.value}
                  >
                    {item.label}
                  </Checkbox>
                );
              })}
            </RadioTileGroup>
            <br />
            <h6 className="pb-2 font-normal">Banheiros</h6>
            <RadioTileGroup
              className="flex-row items-center pl-2 scale-[1.1] gap-0 max-md:scale-[1.17]"
              // defaultValue={
              //   filterBathroomsList[1] ? filterBathroomsList[1] : ""
              // }
            >
              {bedroomsBathroomsValue.map((item) => {
                return (
                  <Checkbox
                    defaultChecked={isInArray(item.value, filterBathroomsList)}
                    onChange={() => {
                      filterBathroomsList.includes(`${item.value}`)
                        ? removeItem(filterBathroomsList, `${item.value}`)
                        : setFilterBathroomsList([
                            ...filterBathroomsList,
                            `${item.value}`,
                          ]);
                    }}
                    style={styleRadioTile}
                    className="max-md:overflow-visible max-md:px-2"
                    value={item.value}
                  >
                    {item.label}
                  </Checkbox>
                );
              })}
            </RadioTileGroup>
            <br />
            <h6 className="pb-2 font-normal">Vagas de Garagem</h6>
            <RadioTileGroup
              className="flex-row items-center gap-0 pl-2 scale-[1.1] max-md:scale-[1.17]"
              // defaultValue={filterGaragesList[1] ? filterGaragesList[1] : ""}
            >
              {bedroomsBathroomsValue.map((item) => {
                return (
                  <Checkbox
                    defaultChecked={isInArray(item.value, filterGaragesList)}
                    onChange={() => {
                      filterGaragesList.includes(`${item.value}`)
                        ? removeItem(filterGaragesList, `${item.value}`)
                        : setFilterGaragesList([
                            ...filterGaragesList,
                            `${item.value}`,
                          ]);
                    }}
                    style={styleRadioTile}
                    className="max-md:overflow-visible max-md:px-2"
                    value={item.value}
                  >
                    {item.label}
                  </Checkbox>
                );
              })}
            </RadioTileGroup>
          </div>

          <div className="p-5 border-b-2 max-md:px-2 max-md:py-4 max-md:mr-7">
            <h4 className="font-semibold">Bairros</h4>
            <br />
            <RadioTileGroup
              inline
              className="grid grid-cols-3 mx-2 max-md:grid-cols-2 max-md:gap-y-1 max-md:gap-x-2"
            >
              {neighborhoodFilter.map((item) => {
                return (
                  <Checkbox
                    defaultChecked={isInArray(
                      item.value.toString(),
                      neighborhoodList
                    )}
                    className="flex items-center scale-[1.14] max-md:scale-125"
                    onChange={() => {
                      filterNeighborhoodList.includes(`${item.value}`)
                        ? removeItem(filterNeighborhoodList, `${item.value}`)
                        : setFilterNeighborhoodList([
                            ...filterNeighborhoodList,
                            `${item.value}`,
                          ]);
                    }}
                  >
                    {item.label}
                  </Checkbox>
                );
              })}
            </RadioTileGroup>
          </div>

          <div className="p-5 border-b-2 max-md:px-2 max-md:py-4 max-md:mr-7">
            <h4 className="font-semibold">Metragem do Imóvel</h4>
            <div className="pt-2">
              <div className="flex items-start justify-start">
                <div className="flex items-center m-2 max-md:m-0">
                  <div className="text-lg ">
                    <span>Área Mínima</span>
                    <br />
                    <div className="flex items-center justify-start">
                      {/* <InputNumber
                        prefix="R$"
                        postfix=".000,00"
                        type="number"
                        id="priceMin"
                        className="flex mt-2 outline-none"
                        size="md"
                        step={25}
                        value={rangeSliderValue[0]}
                        onChange={(nextValue: any) => {
                          const [start, end] = rangeSliderValue;
                          if (nextValue < end) {
                            return;
                          }
                          setRangeSliderValue([nextValue, end]);
                          setFilterMinPrice(["minPrice", nextValue]);
                        }}
                      /> */}
                      <Whisper
                        trigger="focus"
                        speaker={<Tooltip>Tamanho mínimo</Tooltip>}
                        placement="bottom"
                      >
                        <InputGroup
                          inside
                          style={{
                            width: 130,
                            marginBottom: 10,
                          }}
                        >
                          <Input
                            placeholder="0"
                            type="number"
                            value={
                              filterMinArea[1] === "0"
                                ? undefined
                                : filterMinArea[1]?.toString()
                            }
                            onChange={(nextValue: any) => {
                              setFilterMinArea(["minArea", nextValue]);
                            }}
                          />
                          <InputGroup.Addon>m²</InputGroup.Addon>
                        </InputGroup>
                      </Whisper>
                    </div>
                  </div>
                </div>

                <div className="flex px-8 m-2 max-md:m-0">
                  <div className="text-lg">
                    <span>Área Máxima</span>

                    <br />
                    <div className="flex items-center justify-start">
                      {/* <InputNumber
                        prefix="R$"
                        postfix=".000,00"
                        type="number"
                        id="priceMin"
                        className="flex mt-2 outline-none"
                        size="md"
                        step={25}
                        value={rangeSliderValue[0]}
                        onChange={(nextValue: any) => {
                          const [start, end] = rangeSliderValue;
                          if (nextValue < end) {
                            return;
                          }
                          setRangeSliderValue([nextValue, end]);
                          setFilterMinPrice(["minPrice", nextValue]);
                        }}
                      /> */}
                      <Whisper
                        trigger="focus"
                        speaker={<Tooltip>Tamanho máximo</Tooltip>}
                        placement="bottom"
                      >
                        <InputGroup
                          inside
                          style={{
                            width: 130,
                            marginBottom: 10,
                          }}
                        >
                          <Input
                            placeholder="0"
                            type="number"
                            value={
                              filterMaxArea[1] === "0"
                                ? undefined
                                : filterMaxArea[1]?.toString()
                            }
                            onChange={(nextValue: any) => {
                              setFilterMaxArea(["maxArea", nextValue]);
                            }}
                          />
                          <InputGroup.Addon>m²</InputGroup.Addon>
                        </InputGroup>
                      </Whisper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="p-5 border-b-2 max-md:p-2 max-md:mr-7 max-md:pb-6">
            <h4 className="font-semibold">Metragem do imóvel</h4>
            <br />

            <div className="flex items-center justify-center">
              <div className="flex px-4 m-2 max-md:px-0 max-md:w-full">
                <label>
                  Área Mínima
                  <br />
                  <InputNumber
                    type="number"
                    id={"areaMin"}
                    className="mt-2 outline-none"
                    size="lg"
                    postfix={"m²"}
                    step={25}
                    min={0}
                    max={2000}
                    onChange={(value) => {
                      setFilterMinArea(["minArea", String(value)]);
                    }}
                  />
                </label>
              </div>

              <div className="pt-5 text-5xl">-</div>

              <div className="flex px-4 m-2 max-md:px-0 max-md:w-full">
                <label>
                  Área Máxima
                  <br />
                  <InputNumber
                    type="number"
                    id={"areaMax"}
                    className="mt-2 outline-none"
                    size="lg"
                    postfix={"m²"}
                    step={25}
                    min={0}
                    max={2000}
                    onChange={(value) => {
                      setFilterMaxArea(["maxArea", String(value)]);
                    }}
                    // defaultValue={400}
                  />
                </label>
              </div>
            </div>
          </div> */}

          <div className="p-5 max-md:p-2 max-md:mr-7 max-md:pt-6">
            <div className="flex items-center justify-between pr-10 max-md:pr-0 max-md:mr-[-20px]">
              <div>
                <h4 className="text-xl font-semibold">Imóvel com vídeo</h4>
                <h6 className="text-sm font-normal">Veja apenas opções com</h6>
              </div>
              <div>
                <Toggle
                  defaultChecked={filterWithVideoFilter}
                  size={"lg"}
                  checkedChildren={
                    <div className="absolute z-50 right-1.5 top-1.5 fade-in">
                      <BiCheck color="#b23437" size={"18px"} />
                    </div>
                  }
                  onChange={(checked) => {
                    filterWithVideoFilter
                      ? setFilterWithVideoFilter(false)
                      : setFilterWithVideoFilter(true);
                  }}
                />
              </div>
            </div>
            <br />
            <div className="flex items-center justify-between pr-10 max-md:pr-0 max-md:mr-[-20px]">
              <div className="max-md:pr-10">
                <h4 className="text-xl font-semibold">Opções Exclusivas</h4>
                <h6 className="text-sm font-normal">
                  Imóveis que você só encontra na Nossa Casa
                </h6>
              </div>
              <div>
                <Toggle
                  defaultChecked={filterExclusiveFilter}
                  size={"lg"}
                  checkedChildren={
                    <div className="absolute z-50 right-1.5 top-1.5 fade-in">
                      <BiCheck color="#b23437" size={"18px"} />
                    </div>
                  }
                  onChange={() => {
                    filterExclusiveFilter
                      ? setFilterExclusiveFilter(false)
                      : setFilterExclusiveFilter(true);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="flex items-center justify-between p-5 pr-8 border-t-2 max-md:p-0 max-md:pt-2">
            <Link to="/buscar?&">
              <a
                onClick={() => {
                  setFilterNeighborhoodList(["NEIGHBORHOOD"]);
                  setFilterSubTypeList(["SUBTYPE"]);
                  setFilterStatusList(["STATUS"]);
                  setFilterBedroomsList(["BEDROOMS"]);
                  setFilterBathroomsList(["BATHROOMS"]);
                  setFilterGaragesList(["GARAGES"]);
                  setFilterWithVideoFilter(false);
                  setFilterExclusiveFilter(false);
                  setFilterMinPrice(["minPrice"]);
                  setFilterMaxPrice(["maxPrice"]);
                  setFilterMinArea(["minArea"]);
                  setFilterMaxArea(["maxArea"]);
                  setOrderBy(0);
                  setCurrentPage(1);
                  setSearchText("");
                  open ? onClose(false) : onClose(true);
                }}
                className="text-[#3f3f41] underline hover:text-[#b23437]"
              >
                Remover Filtros
              </a>
            </Link>
            <button
              className={`${btnStyle.button2} w-fit text-xl px-10 hover:bg-[#b23437]`}
              style={{ backgroundColor: "#3f3f41" }}
              onClick={() => {
                setNeighborhoodList(filterNeighborhoodList);
                setSubTypeList(filterSubTypeList);
                setBedroomsList(filterBedroomsList);
                setBathroomsList(filterBathroomsList);
                setGaragesList(filterGaragesList);
                setVideoFilter(filterWithVideoFilter);
                setExclusiveFilter(filterExclusiveFilter);
                setMinPrice(filterMinPrice);
                setMaxPrice(filterMaxPrice);
                setMinArea(filterMinArea);
                setMaxArea(filterMaxArea);
                open ? onClose(false) : onClose(true);
                reload ? setReload(false) : setReload(true);
              }}
            >
              Ver Imóveis
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
