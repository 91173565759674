import { Link } from "react-router-dom";
import btnStyle from "../btnStyle.module.css";

interface buttonProps {
  label: string;
  link: string;
}

export function Button({ label, link }: buttonProps) {
  return (
    <>
      <Link to={link}>
        <button className={btnStyle.button}>
          <span>{label}</span>
        </button>
      </Link>
    </>
  );
}
