import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { InfoApto } from "../apto.type";
import { api } from "../api";
import { Carousel } from "react-responsive-carousel";
import btnStyle from "../btnStyle.module.css";
import { RxCheck } from "react-icons/rx";
import { Input, Breadcrumb, Modal } from "rsuite";
import { neighborhoodFilter } from "../components/FilterBar";
import { Button } from "../components/Button";
import { RiCloseLine, RiLoader2Line, RiMapPin2Fill } from "react-icons/ri";
import GoogleMapReact from "google-map-react";
import mapStyleJson from "../mapStyle.json";
import { arrowFoward, arrowPrev } from "../components/Arrows";
import {
  BiArea,
  BiArrowBack,
  BiSolidBed,
  BiSolidCarGarage,
} from "react-icons/bi";
import { useZorm } from "react-zorm";
import { contactFormSchema, newsletterFormSchema } from "../contact.schema";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

let info: InfoApto;

export function findNeighborhood(thisValue: number) {
  const labelOfValue = neighborhoodFilter.find(
    (value) => value.value === thisValue
  );
  return labelOfValue?.label;
}

export function Properties() {
  let { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const mapStyle = mapStyleJson;

  const carouselYoutubeMobileRelative =
    window.innerWidth > 768
      ? {
          centerMode: true,
          percentage: 34,
          initial: 2,
          infinity: true,
          swipeable: false,
          arrows: true,
        }
      : {
          centerMode: true,
          percentage: 95,
          initial: 3,
          infinity: true,
          swipeable: true,
          arrows: false,
        };

  const carouselYoutubeMobile =
    window.innerWidth > 768
      ? window.innerWidth > 2200
        ? {
            mobile: false,
            percentage: 45,
            width: 1030,
            height: 540,
            arrows: true,
          }
        : {
            mobile: false,
            percentage: 60,
            width: 1080,
            height: 540,
            arrows: true,
          }
      : {
          mobile: true,
          percentage: 87,
          width: 340,
          height: 256,
          arrows: true,
        };

  const [infoApto, setInfoApto] = useState(info);
  const [isLoading, setIsLoading] = useState(false);
  const [initialImage, setInitialImage] = useState(2);

  const [imageFullScreen, setImageFullScreen] = useState([Boolean(false), 1]);

  const [emailLoading, setEmailLoading] = useState(false);

  async function getAptos() {
    setIsLoading(true);
    const data = await api.get(`properties/${id}`);
    return setInfoApto(data.data);
  }

  useEffect(() => {
    getAptos().then(() => {
      setIsLoading(false);
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
    return;
  }, [location]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://d335luupugsy2.cloudfront.net/js/loader-scripts/98531bad-3c6c-46fc-ab38-0de641c99e27-loader.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // }, [location]);

  const description =
    infoApto?.data.notes === null
      ? null
      : String(infoApto?.data.notes)
          .split("\n")
          .map((item, i) => (i % 2 === 0 ? item + <br /> : null))
          .map((item) => item?.slice(0, -15))
          .map((item) => {
            return <p>{item}</p>;
          });

  useEffect(() => emailjs.init("6KUX_UqfosafJ4VF5"), []);

  const zorm = useZorm(`/imovel/${id}`, contactFormSchema, {
    async onValidSubmit(event) {
      event.preventDefault();
      const sendData = {
        from_name: event.data.name,
        from_email: event.data.email,
        phone: event.data.phone,
        contact: event.data.contactWay,
        code: id,
      };

      setEmailLoading(true);

      try {
        emailjs.send("service_a75p5lo", "template_ctfa7zf", sendData).then(
          (result) => {
            toast.success("Email enviado com sucesso!");
            setEmailLoading(false);
          },
          (error) => {
            toast.error("Tente novamente em instantes...");
            setEmailLoading(false);
          }
        );
      } catch (e) {
        console.log(e);
      }
      return event.target.reset();
      //email rd-station
      // alert("lead enviado com sucesso!");
      // console.log("send contact");
    },
  });

  const newsletter = useZorm(`/imovel/${id}-newsletter`, newsletterFormSchema, {
    async onValidSubmit(event) {
      event.preventDefault();
      const sendData = {
        from_name: event.data.name,
        from_email: event.data.email,
        phone: "",
        contact: "Quero receber conteúdos exclusivos!",
        code: id,
      };

      setEmailLoading(true);

      try {
        emailjs.send("service_a75p5lo", "template_ctfa7zf", sendData).then(
          (result) => {
            toast.success("Email enviado com sucesso!");
            setEmailLoading(false);
          },
          (error) => {
            toast.error("Tente novamente em instantes...");
            setEmailLoading(false);
          }
        );
      } catch (e) {
        console.log(e);
      }
      return event.target.reset();
    },
  });

  let contactDisabled = zorm.validation?.success === false;
  let newsletterDisabled = newsletter.validation?.success === false;

  const [emailForm, setEmailForm] = useState("");
  const [nameForm, setNameForm] = useState("");
  const [phoneForm, setPhoneForm] = useState("");

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center mt-32 mb-20 max-md:mt-44">
          <RiLoader2Line
            size={150}
            color="#b23437"
            className="animate-[spin_5s_linear_infinite]"
          />
        </div>
      ) : (
        <>
          {/* <Helmet>
            <meta name="description" content={infoApto?.data.ad_title} />
            <meta property="og:image" content={infoApto?.data.images[0].url} />
          </Helmet> */}
          {/* IMAGE FULLSCREEN */}
          {infoApto?.data.active ? (
            <>
              <Modal
                dialogClassName={"modal-carousel"}
                className="flex items-center justify-center mt-[-10px] max-h-screen max-w-full max-md:mt-[5%] max-md:items-center max-2xl:mt-[8%] max-md:touch-none"
                size="full"
                open={Boolean(imageFullScreen[0])}
                onOpen={() => {
                  carouselYoutubeMobileRelative.arrows
                    ? (
                        document
                          .getElementsByClassName("thumb")
                          .item(0) as HTMLElement
                      )?.focus()
                    : (document
                        .getElementsByClassName("thumb")
                        .item(0) as HTMLElement);
                }}
                backdrop={true}
                backdropClassName="propertie-modal-backdrop"
                onClose={() => {
                  setImageFullScreen([false, Number(imageFullScreen[1])]);
                }}
              >
                <Modal.Header classPrefix="fullscreen-propertie">
                  '
                </Modal.Header>
                <Carousel
                  infiniteLoop={true}
                  useKeyboardArrows={true}
                  showArrows={false}
                  showIndicators={false}
                  showThumbs={carouselYoutubeMobileRelative.arrows}
                  thumbWidth={100}
                  dynamicHeight={false}
                  showStatus={false}
                  transitionTime={850}
                  centerMode
                  centerSlidePercentage={100}
                  selectedItem={Number(imageFullScreen[1])}
                  onChange={(i) => setInitialImage(i)}
                  onClickItem={(index, item) => {
                    setInitialImage(index);
                    return initialImage === index
                      ? setImageFullScreen([true, index])
                      : null;
                  }}
                  renderArrowNext={
                    carouselYoutubeMobileRelative.arrows
                      ? (clickHandler) =>
                          arrowFoward({
                            onClickHandler: clickHandler,
                            size: 80,
                          })
                      : undefined
                  }
                  renderArrowPrev={
                    carouselYoutubeMobileRelative.arrows
                      ? (clickHandler) =>
                          arrowPrev({
                            onClickHandler: clickHandler,
                            size: 80,
                          })
                      : undefined
                  }
                  className="flex flex-col items-center justify-center max-h-[90vh] carousel-exclusivos-inner max-xl:max-h-[125vh] max-2xl:max-h-[140vh] max-md:mx-[-90px]"
                >
                  {infoApto?.data.images.map((item) => {
                    return (
                      // <div className="w-[100%] cursor-pointer max-md:pt-44">
                      <img
                        src={item?.url}
                        className="object-contain align-middle max-w-7xl px-2 max-h-[75vh] max-md:mt-44"
                      />
                      // </div>
                    );
                  })}
                </Carousel>
                {/* <img
              className="top-0 left-0 max-w-screen-xl overflow-scroll rounded-2xl max-md:max-w-screen-sm max-md:min-w-[20rem]"
              src={infoApto?.data.images[Number(imageFullScreen[1])]?.url}
              onClick={() => {
                setImageFullScreen([false, Number(imageFullScreen[1])]);
              }}
            /> */}
              </Modal>
              <div className="pt-32 " />

              <div className="flex items-center justify-start mx-[21%] max-md:mr-[5%] max-md:ml-[7%] leading-5 max-md:px-2 min-[2200px]:mx-[28%]">
                <Breadcrumb maxItems={7} separator={<>{`>`}</>}>
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="/imovel">Buscar</Breadcrumb.Item>
                  <Breadcrumb.Item href="/imovel">Torres</Breadcrumb.Item>
                  <Breadcrumb.Item
                    href={`/imovel?neighborhood=${infoApto?.data.neighborhood_id}`}
                  >
                    {findNeighborhood(infoApto?.data.neighborhood_id)}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    href={`/imovel?bedrooms=${infoApto?.data.bedrooms}&neighborhood=${infoApto?.data.neighborhood_id}`}
                  >
                    {infoApto?.data.bedrooms} Dormitórios
                  </Breadcrumb.Item>

                  <Breadcrumb.Item active>
                    {infoApto?.data.ad_title}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="flex flex-col items-center justify-center pb-24 overflow-x-hidden bg-transparent max-md:pb-10">
                <Carousel
                  infiniteLoop={true}
                  useKeyboardArrows={true}
                  showArrows={false}
                  showIndicators={false}
                  showThumbs={false}
                  showStatus={false}
                  transitionTime={850}
                  centerMode
                  centerSlidePercentage={carouselYoutubeMobile.percentage}
                  swipeScrollTolerance={45}
                  preventMovementUntilSwipeScrollTolerance
                  selectedItem={0}
                  onChange={(i) => setInitialImage(i)}
                  onClickItem={(index, item) => {
                    setInitialImage(index);
                    return initialImage === index
                      ? setImageFullScreen([true, index])
                      : null;
                  }}
                  className="flex items-center justify-center object-cover rounded-3xl w-[100%] carousel-control-div"
                >
                  {infoApto?.data.images.map((item) => {
                    return (
                      <div className="w-[100%] rounded-3xl h-[600px] max-w-7xl cursor-pointer max-md:h-auto">
                        <img
                          src={item?.url}
                          className="object-cover rounded-3xl h-[600px] w-[100%] max-w-7xl px-2 max-md:h-auto max-md:max-h-64 max-md:min-h-[16rem]"
                        />
                      </div>
                    );
                  })}
                </Carousel>
                {!infoApto?.data.videos.length ? null : (
                  <>
                    <div className="mt-8 rounded-3xl">
                      <iframe
                        className="rounded-3xl"
                        width={carouselYoutubeMobile.width}
                        height={carouselYoutubeMobile.height}
                        src={`https://www.youtube.com/embed/${infoApto?.data.videos[0]?.url}?controls=1&modestbranding=1&amp;rel=0&amp;showinfo=0`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                      />
                    </div>
                  </>
                )}
                <div className="flex max-w-5xl mt-10 text-[#1f1f21] space-x-16 max-md:flex-col max-md:max-w-sm max-md:pl-4 max-md:space-x-0">
                  <div className="flex flex-col flex-1 space-y-2 scale-105 max-md:space-y-4">
                    <h2 className="text-3xl text-[#1f1f21] pr-4 pt-4 max-md:text-center">
                      {infoApto?.data.ad_title}
                    </h2>

                    <span className="text-[#1f1f21] max-md:text-center">
                      <i>
                        {findNeighborhood(infoApto?.data.neighborhood_id)} |
                        Torres | Código do imóvel{" "}
                        {infoApto?.data.identifier_code}
                      </i>
                    </span>

                    <h2 className="text-[#1f1f21] max-md:text-center max-md:text-3xl">
                      {"R$" +
                        infoApto?.data.sale_value.replace(
                          /(\d)(?=(\d{3})+(?!\d))/g,
                          "$1."
                        )}
                    </h2>
                    {carouselYoutubeMobile.mobile ? (
                      <div className="mx-4 border-t-2 border-solid">{""}</div>
                    ) : null}
                    <span
                      className={`pt-5 pl-0.5 ${
                        !infoApto?.data.private_area &&
                        !infoApto?.data.garages &&
                        !infoApto?.data.bedrooms &&
                        !infoApto?.data.iptu_value &&
                        !infoApto?.data.suites &&
                        !infoApto?.data.show_condominium_value
                          ? "hidden"
                          : null
                      }`}
                    >
                      <b>Informações:</b>
                    </span>
                    <ul className="grid grid-cols-2 font-medium gap-y-2">
                      {!infoApto?.data.private_area ? null : (
                        <li>
                          {infoApto?.data.private_area}m² de área privativa
                        </li>
                      )}
                      {!infoApto?.data.terrain_area ? null : (
                        <li>Terreno de {infoApto?.data.terrain_area}m²</li>
                      )}
                      {!infoApto?.data.garages ? null : (
                        <li>
                          {infoApto?.data.garages}{" "}
                          {infoApto?.data.garages === 1
                            ? "vaga de garagem"
                            : "vagas de garagem"}
                        </li>
                      )}

                      {!infoApto?.data.bedrooms ? null : (
                        <li>{infoApto?.data.bedrooms} dormitórios</li>
                      )}

                      {!infoApto?.data.iptu_value ? null : (
                        <li>
                          IPTU: R$
                          {infoApto?.data.iptu_value.replace(
                            /(\d)(?=(\d{3})+(?!\d))/g,
                            "$1."
                          )}
                        </li>
                      )}

                      {!infoApto?.data.suites ? null : (
                        <li>
                          {infoApto?.data.suites}{" "}
                          {infoApto?.data.suites === 1 ? "suíte" : "suítes"}
                        </li>
                      )}

                      {infoApto?.data.show_condominium_value ? (
                        <li>
                          Condominio: R$
                          {infoApto?.data.condominium_value.replace(
                            /(\d)(?=(\d{3})+(?!\d))/g,
                            "$1."
                          )}
                        </li>
                      ) : null}
                    </ul>
                    <span className="pt-5 pl-0.5">
                      <b>Descrição:</b>
                    </span>
                    <span className="pr-4 font-medium">{description}</span>
                    <span
                      className={`pt-5 pl-0.5 ${
                        infoApto?.data.property_infrastructures.length === 0
                          ? "hidden"
                          : null
                      }`}
                    >
                      <b>Comodidades:</b>
                    </span>
                    <ul className="grid grid-cols-2 font-medium gap-y-2">
                      {infoApto?.data.property_infrastructures.map((item) => {
                        return (
                          <li className="flex items-center">
                            <RxCheck size={20} />
                            {item}
                          </li>
                        );
                      })}
                      {/* <li className="flex items-center">
              <RxCheck size={20} />
              Area Total
            </li>
            <li className="flex items-center">
              <RxCheck size={20} />
              Dormitórios
            </li>
            <li className="flex items-center">
              <RxCheck size={20} />
              Suites
            </li>
            <li className="flex items-center">
              <RxCheck size={20} />
              Vagas de Garagem
            </li>
            <li className="flex items-center">
              <RxCheck size={20} />
              IPTU
            </li>
            <li className="flex items-center">
              <RxCheck size={20} />
              condomínio
            </li> */}
                    </ul>
                  </div>
                  <div className="flex flex-col items-center flex-1 max-md:mt-10">
                    {/* <div className="text-white py-28 px-56 rounded-3xl mb-[-50px] z-40 shadow-xl max-md:px-40"> */}

                    {/* <Map
                  google={"AIzaSyCU6Z8Q0FQ5FPhJWonDtcRUdFKqbVWgX7U"}
                  center={{ lat: 0, lng: 0 }}
                /> */}
                    <div
                      style={{
                        height: "30vh",
                        width: "90%",
                        marginBottom: -45,
                        borderRadius: 15,
                        overflow: "hidden",
                      }}
                      className="shadow-xl"
                    >
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key: "AIzaSyCU6Z8Q0FQ5FPhJWonDtcRUdFKqbVWgX7U",
                          language: "pt-br",
                        }}
                        center={{
                          lat: Number(infoApto?.data.latitude)
                            ? Number(infoApto?.data.latitude)
                            : 0,
                          lng: Number(infoApto?.data.longitude)
                            ? Number(infoApto?.data.longitude)
                            : 0,
                        }}
                        zoom={15}
                        options={{
                          styles: mapStyle,
                          clickableIcons: false,
                          gestureHandling: "none",
                        }}
                      >
                        <RiMapPin2Fill
                          size={40}
                          color="#b23437"
                          className="translate-x-[-25px] translate-y-[-40px] font-thin"
                        />
                      </GoogleMapReact>
                    </div>

                    {/* </div> */}

                    <div className="px-20 pt-20 bg-white shadow-lg rounded-3xl max-md:px-6">
                      <h3 className="text-[#1f1f21] text-center text-xl pb-6">
                        Quer visitar ou saber mais sobre esse imóvel?
                      </h3>
                      <form
                        className="flex flex-col gap-y-2"
                        ref={zorm.ref}
                        id="forms-site"
                      >
                        <Input
                          placeholder="Nome Completo"
                          id="name"
                          type="text"
                          name={zorm.fields.name()}
                          value={nameForm}
                          onChange={(i) => {
                            setNameForm(i);
                          }}
                        />
                        {zorm.errors.name((error) => {
                          return (
                            <span className="px-1 text-sm text-[#A81F24] max-md:text-center">
                              {error.message}
                            </span>
                          );
                        })}
                        <Input
                          placeholder="Telefone"
                          id="phone"
                          name={zorm.fields.phone()}
                          value={phoneForm}
                          onChange={(i) => {
                            setPhoneForm(i);
                          }}
                          type="number"
                          pattern="[0-9]*"
                        />
                        {zorm.errors.phone((error) => {
                          return (
                            <span className="px-1 text-sm text-[#A81F24] max-md:text-center">
                              {error.message}
                            </span>
                          );
                        })}
                        <Input
                          className="email-form"
                          placeholder="Email"
                          id="email"
                          type="email"
                          name={zorm.fields.email()}
                          value={emailForm}
                          onChange={(i) => {
                            setEmailForm(i);
                          }}
                        />
                        {zorm.errors.email((error) => {
                          return (
                            <span className="px-1 text-sm text-[#A81F24] max-md:text-center">
                              {error.message}
                            </span>
                          );
                        })}
                        <Input
                          placeholder="Forma de Contato"
                          id="contact"
                          name={zorm.fields.contactWay()}
                        />

                        <div className="flex justify-center pb-10">
                          <button
                            disabled={contactDisabled}
                            className={`${
                              btnStyle.button2
                            } w-fit px-28 py-2 bg-[#A81F24] font-semibold text-base rounded-lg ${
                              emailLoading ? "cursor-wait" : ""
                            }`}
                            type="submit"
                          >
                            Enviar
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="pt-32 ">
              <h3 className="py-20 px-44 max-md:px-10">
                Imóvel indisponível...
              </h3>
            </div>
          )}
          <section className="pt-10 pb-10 border-t-4 bg-white border-t-[#b23437] max-md:py-10 max-md:overflow-hidden">
            <div className="flex flex-col items-center justify-center">
              <div className="flex flex-col items-center justify-center pb-4 scale-125 max-md:scale-100">
                <h3 className="text-[#b23437] font-bold">Imóveis similares</h3>
                <span className="text-[#AEAEAE] text-center px-10">
                  Continue sua busca
                </span>
              </div>

              <div className="grid-flow-col grid-cols-3 max-w-7xl max-md:max-w-full max-md:scale-95 max-[950px]:max-w-[900px]">
                <Carousel
                  swipeable={carouselYoutubeMobileRelative.swipeable}
                  showThumbs={false}
                  showIndicators={false}
                  showArrows={carouselYoutubeMobileRelative.arrows}
                  showStatus={false}
                  transitionTime={1000}
                  swipeScrollTolerance={45}
                  preventMovementUntilSwipeScrollTolerance
                  centerMode={carouselYoutubeMobileRelative.centerMode}
                  centerSlidePercentage={
                    carouselYoutubeMobileRelative.percentage
                  }
                  selectedItem={carouselYoutubeMobileRelative.initial}
                  infiniteLoop={carouselYoutubeMobileRelative.infinity}
                  className="carousel-exclusivos"
                  renderArrowNext={
                    carouselYoutubeMobileRelative.arrows
                      ? (clickHandler) =>
                          arrowFoward({
                            onClickHandler: clickHandler,
                            size: 20,
                          })
                      : undefined
                  }
                  renderArrowPrev={
                    carouselYoutubeMobileRelative.arrows
                      ? (clickHandler) =>
                          arrowPrev({
                            onClickHandler: clickHandler,
                            size: 20,
                          })
                      : undefined
                  }
                >
                  {infoApto?.relativeProperties.map((info, i) => {
                    return (
                      <div
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                        key={i}
                        className="scale-95 bg-white flex flex-col justify-between space-y-0 h-auto border-solid border-2 border-[#F5F5F5] max-w-lg m-2 rounded-xl pb-10 min-h-[32rem] max-md:ml-0 max-md:mr-5 max-md:scale-100 max-md:max-w-lg max-md:min-h-[34rem]"
                      >
                        {/* {carouselYoutubeMobile.arrows ? null : (
                          <div className="bg-transparent w-[40%] h-[70%] z-50 fixed left-20 touch-pan-x">
                            {" "}
                          </div>
                        )} */}
                        <Carousel
                          infiniteLoop
                          swipeable={false}
                          transitionTime={850}
                          showStatus={false}
                          showThumbs={false}
                          className="carousel-exclusivos-inner"
                          renderArrowNext={(clickHandler) =>
                            arrowFoward({
                              onClickHandler: clickHandler,
                              size: 30,
                            })
                          }
                          renderArrowPrev={(clickHandler) =>
                            arrowPrev({
                              onClickHandler: clickHandler,
                              size: 30,
                            })
                          }
                        >
                          {info?.images.slice(0, 4).map((item, i) => {
                            return (
                              <>
                                <div
                                  className="max-w-lg rounded-t-xl h-72 max-md:h-80"
                                  onClick={(event) => event.preventDefault()}
                                >
                                  {info?.has_exclusivity && i === 0 ? (
                                    <>
                                      <div className="">
                                        <button className="bg-[#DAA14A] text-xs pointer-events-none fixed top-4 right-[-7rem] w-28 rounded-r-xl text-white pr-4 py-2 text-right">
                                          Exclusividade
                                        </button>
                                      </div>
                                    </>
                                  ) : null}
                                  <img
                                    loading="lazy"
                                    src={item.thumbnail_url}
                                    className="rounded-t-xl h-72 max-md:h-80"
                                  />
                                </div>
                              </>
                            );
                          })}
                        </Carousel>
                        <div className="flex flex-col items-center justify-center">
                          <span className="px-2 pt-3 pb-1 text-xl font-bold">
                            {"R$" +
                              info?.sale_value.replace(
                                /(\d)(?=(\d{3})+(?!\d))/g,
                                "$1."
                              )}
                          </span>
                          {info.meta_title ? (
                            <h6 className="px-10 py-2 text-sm font-normal text-center">
                              {info?.meta_title.length >= 100
                                ? `${info?.meta_title.substring(0, 50)}...`
                                : info?.meta_title}
                            </h6>
                          ) : (
                            <br />
                          )}
                        </div>
                        <div className="flex justify-center gap-4 mx-5 text-xs font-medium max-md:mx-2 max-md:gap-4">
                          {info.private_area ? (
                            <span
                              className={`flex items-center justify-center gap-1 ${
                                info?.private_area ? "flex" : "hidden"
                              }`}
                            >
                              <BiArea size={"22px"} />
                              {info?.private_area} m²
                            </span>
                          ) : (
                            <br />
                          )}
                          <span
                            className={`items-center justify-center gap-1 ${
                              info?.bedrooms ? "flex" : "hidden"
                            }`}
                          >
                            <BiSolidBed size={"22px"} />
                            {info?.bedrooms} Dormitórios
                            {info?.bedrooms === 0 ? <br /> : null}
                          </span>
                          <span
                            className={`items-center justify-center gap-1 ${
                              info?.garages ? "flex" : "hidden"
                            }`}
                          >
                            <BiSolidCarGarage size={"22px"} />
                            {info?.garages} Vagas
                            {info?.garages === 0 ? <br /> : null}
                          </span>
                        </div>
                        <div className="flex items-center justify-center pt-4">
                          <Button
                            label="Saiba mais"
                            link={`/imovel/${info?.identifier_code}`}
                          />
                        </div>
                      </div>
                    );
                  })}
                </Carousel>

                <span
                  className="flex items-center justify-center text-[#1f1f21] hover:underline cursor-pointer max-md:pt-10"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <BiArrowBack color="#b23437" size={20} />
                  <h6 className="pl-1">voltar</h6>
                </span>
              </div>
            </div>
          </section>
          <section className="bg-[#b23437f8] pt-10 pb-3 flex items-center justify-center pl-4 pr-12 max-md:pl-12">
            <div className="flex items-center justify-center max-w-5xl text-white max-md:flex-col">
              <div className="mr-5 max-md:ml-5 max-md:pt-8">
                <h5 className="text-3xl font-normal max-md:text-center max-md:text-2xl max-md:px-4">
                  <b>Quer receber conteúdos exclusivos?</b>
                </h5>
                <p className="text-base font-normal max-md:ml-5 max-md:mt-5">
                  As melhores informações sobre o mercado imobiliário, dicas de
                  como curtir a praia, curiosidades de Torres/RS, programação
                  semanal e muito mais.
                </p>
              </div>

              <div className="border-l-[#C76C6E] border-l-2 max-h-48 mb-6 max-md:border-l-0">
                <form
                  className="flex flex-col p-10 gap-y-2"
                  ref={newsletter.ref}
                >
                  <Input
                    placeholder="Nome"
                    id="name"
                    name={newsletter.fields.name()}
                    type="text"
                  />
                  {newsletter.errors.name((error) => {
                    return (
                      <span className="px-1 text-sm text-white max-md:text-center">
                        {error.message}
                      </span>
                    );
                  })}
                  <Input
                    placeholder="Email"
                    id="email-news"
                    name={newsletter.fields.email()}
                    type="email"
                  />
                  {newsletter.errors.email((error) => {
                    return (
                      <span className="px-1 text-sm text-white max-md:text-center">
                        {error.message}
                      </span>
                    );
                  })}
                  <button
                    disabled={newsletterDisabled}
                    className={`${
                      btnStyle.button2
                    } w-fit px-28 py-2 bg-[#A81F24] font-semibold text-base rounded-lg ${
                      emailLoading ? "cursor-wait" : ""
                    }`}
                    type="submit"
                  >
                    Enviar
                  </button>
                </form>
              </div>
            </div>
          </section>
          <ToastContainer />
        </>
      )}
    </>
  );
}
