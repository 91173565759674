import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

interface Arrow {
  onClickHandler: () => void;
  size: number;
}

export function arrowPrev({ onClickHandler, size }: Arrow) {
  return (
    <IoIosArrowBack
      onClick={onClickHandler}
      size={size}
      color="white"
      className="z-40 control-arrow control-prev"
    />
  );
}

export function arrowFoward({ onClickHandler, size }: Arrow) {
  return (
    <IoIosArrowForward
      onClick={onClickHandler}
      size={size}
      color="white"
      className="z-40 control-arrow control-next"
    />
  );
}

export function arrowPrevWhite({ onClickHandler }: Arrow) {
  return (
    <IoIosArrowBack
      onClick={onClickHandler}
      size={30}
      color="white"
      className="z-40 control-arrow control-prev"
    />
  );
}

export function arrowFowardWhite({ onClickHandler }: Arrow) {
  return (
    <IoIosArrowForward
      onClick={onClickHandler}
      size={30}
      color="white"
      className="z-40 control-arrow control-next"
    />
  );
}
