import { Drawer, Dropdown, Modal, Nav, Sidebar, Sidenav } from "rsuite";
import logo from "../imgs/logo.png";
import { FaWhatsapp } from "react-icons/fa";
import { RxCross2, RxHamburgerMenu } from "react-icons/rx";
import "rsuite/dist/rsuite.min.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

export function AppBar() {
  const [sidenavOpen, setSidenavOpen] = useState(false);

  return (
    <>
      <section>
        <Nav className="fixed top-0 z-[100] flex items-center w-full py-5 bg-white shadow-md pl-28 pr-36 justify-evenly max-lg:hidden min-[2200px]:justify-center min-[2200px]:pl-0">
          <Link to="/" className="resize-none">
            <div className="flex items-center justify-center resize-none min-[2200px]:justify-end">
              <img
                src={logo}
                className="max-w-[30%] resize-none min-w-[7rem]"
              />
            </div>
          </Link>

          <div>
            <Nav className="flex items-center gap-2 pl-10 text-base leading-4">
              <Nav.Item href="/">Início</Nav.Item>
              <Nav.Item href="/quem-somos">Quem somos</Nav.Item>
              <Dropdown title={"Comprar"} className="pt-1 dropdown">
                <Dropdown.Item
                  onClick={() => {
                    window.location.assign("/imovel");
                  }}
                >
                  Comprar
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => {
                    window.location.assign("/imovel?exclusive=true");
                  }}
                >
                  Exclusividades
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => {
                    window.location.assign(
                      "imovel?&subtype=Casa&subtype=Apartamento&subtype=Casa%20de%20Condomínio&subtype=Cobertura&subtype=Geminado&subtype=Sobrado&subtype=Studio&subtype=Triplex&subtype=Kitnet&subtype=Duplex&subtype=Loft"
                    );
                  }}
                >
                  Residenciais
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => {
                    window.location.assign(
                      "/imovel?&subtype=Sala%20Comercial&subtype=Terreno%20Comercial"
                    );
                  }}
                >
                  Comerciais
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => {
                    window.location.assign("/imovel?&subtype=Terreno");
                  }}
                >
                  Terrenos
                </Dropdown.Item>
              </Dropdown>
              <Nav.Item href="/lancamentos">Lançamentos</Nav.Item>
              <Nav.Item href="/vender">Vender</Nav.Item>
              <Dropdown
                title={"Conteúdos Exclusivos"}
                className="pt-1 dropdown"
              >
                <a
                  href="https://www.youtube.com/playlist?list=PL-5T6scHbluWG0CU50sZ8bV7DF2_o1WuC"
                  target="blank"
                >
                  <Dropdown.Item>TV Nossa Casa</Dropdown.Item>
                </a>
                <a href="https://radionossacasa.com.br/" target="blank">
                  <Dropdown.Item>Rádio Nossa Casa</Dropdown.Item>
                </a>
                <a href="https://www.nossacasa.com.br/blog" target="blank">
                  <Dropdown.Item>Blog</Dropdown.Item>
                </a>
                <a
                  href="https://www.instagram.com/nossacasaimoveis/"
                  target="blank"
                >
                  <Dropdown.Item>Instagram</Dropdown.Item>
                </a>
                <a
                  href="https://radionossacasa.com.br/player-video/"
                  target="blank"
                >
                  <Dropdown.Item>Câmera AO VIVO</Dropdown.Item>
                </a>
                <a
                  href="https://www.youtube.com/watch?v=qOU7laVWwbU&list=PL-5T6scHbluUNrJQGA5XmFBBOPSGnnuJ_"
                  target="blank"
                >
                  <Dropdown.Item>Imóvel na TV</Dropdown.Item>
                </a>
              </Dropdown>
              {/* <Nav.Item href="/">
                <b className="text-[#434343]">Aluguéis</b>
              </Nav.Item> */}
            </Nav>
          </div>
          <p
            className="mr-20 no-underline cursor-pointer"
            onClick={() => {
              document.getElementById("rd-floating_button-lfv54mcx")?.click();
            }}
          >
            <div className="text-[#b23437] flex items-center gap-2 font-bold text-md pl-12">
              WhatsApp <FaWhatsapp size={22} />
            </div>
          </p>
        </Nav>
      </section>

      <Nav className="fixed top-0 z-50 items-center justify-between hidden w-full py-6 pl-6 pr-6 bg-white shadow-md max-lg:flex">
        <a href="/">
          <div className="flex justify-start">
            <img src={logo} className="max-w-[30%]" />
          </div>
        </a>
        <div className="flex-1 mb-[-1.2rem] ml-[-1.5rem]">
          {/* <h6 className="font-normal text-[#b23437] text-sm">Nossa Casa</h6> */}
        </div>
        <div
          onClick={() => {
            sidenavOpen ? setSidenavOpen(false) : setSidenavOpen(true);
          }}
        >
          <RxHamburgerMenu size={35} color="#b23437" />
        </div>
      </Nav>

      <Drawer
        open={sidenavOpen}
        placement="right"
        onClose={() => setSidenavOpen(false)}
        className="max-w-[75%] overflow-scroll"
      >
        <div className="flex items-center justify-between ml-10 mr-5 mt-7 mb-7">
          <img src={logo} className="max-w-[35%]" />
          <RxCross2
            size={45}
            color="#b23437"
            onClick={() => {
              sidenavOpen ? setSidenavOpen(false) : setSidenavOpen(true);
            }}
          />
        </div>
        <div className="text-center p-5 font-bold text-[#3E3E3E] text-xl bg-[#F3F3F3]">
          MENU
        </div>
        <div className="w-full inline-table">
          <Sidenav className="flex flex-col items-center justify-start w-full space-y-2 text-xl">
            <Nav>
              <Nav.Item href="/">Início</Nav.Item>
              <Nav.Item href="/quem-somos">Quem somos</Nav.Item>

              <Nav.Menu title={"Comprar"}>
                <Nav.Item
                  className="bg-[#F3F3F3]"
                  onClick={() => {
                    window.location.assign("/imovel");
                  }}
                >
                  Comprar
                </Nav.Item>

                <Nav.Item
                  className="bg-[#F3F3F3]"
                  onClick={() => {
                    window.location.assign("/imovel?exclusive=true");
                  }}
                >
                  Exclusividades
                </Nav.Item>

                <Nav.Item
                  className="bg-[#F3F3F3]"
                  onClick={() => {
                    window.location.assign(
                      "imovel?&subtype=Casa&subtype=Apartamento&subtype=Casa%20de%20Condomínio&subtype=Cobertura&subtype=Geminado&subtype=Sobrado&subtype=Studio&subtype=Triplex&subtype=Kitnet&subtype=Duplex&subtype=Loft"
                    );
                  }}
                >
                  Residenciais
                </Nav.Item>

                <Nav.Item
                  className="bg-[#F3F3F3]"
                  onClick={() => {
                    window.location.assign(
                      "/imovel?&subtype=Sala%20Comercial&subtype=Terreno%20Comercial"
                    );
                  }}
                >
                  Comerciais
                </Nav.Item>

                <Nav.Item
                  className="bg-[#F3F3F3]"
                  onClick={() => {
                    window.location.assign("/imovel?&subtype=Terreno");
                  }}
                >
                  Terrenos
                </Nav.Item>
              </Nav.Menu>
              <Nav.Item href="/lancamentos">Lançamentos</Nav.Item>
              <Nav.Item href="/vender">Vender</Nav.Item>
              <Nav.Menu title={"Conteúdos Exclusivos"} className="ml-[-10px]">
                <Nav.Item
                  href="https://www.youtube.com/playlist?list=PL-5T6scHbluWG0CU50sZ8bV7DF2_o1WuC"
                  target="blank"
                  className="bg-[#F3F3F3]"
                >
                  TV Nossa Casa
                </Nav.Item>
                <Nav.Item
                  href="https://radionossacasa.com.br/"
                  target="blank"
                  className="bg-[#F3F3F3]"
                >
                  Rádio Nossa Casa
                </Nav.Item>
                <Nav.Item
                  href="https://www.nossacasa.com.br/blog"
                  target="blank"
                  className="bg-[#F3F3F3]"
                >
                  Blog
                </Nav.Item>
                <Nav.Item
                  href="https://www.instagram.com/nossacasaimoveis/"
                  target="blank"
                  className="bg-[#F3F3F3]"
                >
                  Instagram
                </Nav.Item>
                <Nav.Item
                  href="https://radionossacasa.com.br/player-video/"
                  target="blank"
                  className="bg-[#F3F3F3]"
                >
                  Câmera AO VIVO
                </Nav.Item>
                <Nav.Item
                  href="https://www.youtube.com/watch?v=qOU7laVWwbU&list=PL-5T6scHbluUNrJQGA5XmFBBOPSGnnuJ_"
                  target="blank"
                  className="bg-[#F3F3F3]"
                >
                  Imóvel na TV
                </Nav.Item>
              </Nav.Menu>
              {/* <Nav.Item href="/">
                <b className="text-[#434343]">Aluguéis</b>
              </Nav.Item> */}
            </Nav>
          </Sidenav>
        </div>
      </Drawer>
      {/* 
      <div
        className={`top-0 right-0 z-50 min-w-full min-h-full bg-black bg-opacity-30 ${"hidden"} fade-in`}
        onClick={() => {
          sidenavOpen ? setSidenavOpen(false) : setSidenavOpen(true);
        }}
      >
        <div className="flex items-center justify-end h-screen">
          <div
            className="w-3/6 h-screen bg-[#F7F7FA] slide shadow-2xl"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div className="flex items-center justify-end m-5 ml-10">
              <RxCross2
                size={20}
                color="#b23437"
                onClick={() => {
                  sidenavOpen ? setSidenavOpen(false) : setSidenavOpen(true);
                }}
              />
            </div>
            <div className="w-full inline-table">
              <Sidenav expanded>
                <Nav className="flex flex-col items-center text-base">
                  <Nav.Item href="/">Início</Nav.Item>
                  <Nav.Item href="/quem-somos">Quem somos</Nav.Item>
                  <></>
                  <Nav.Menu title={"Comprar"} className="dropdown">
                    <Nav.Item
                      onClick={() => {
                        window.location.assign("/imovel");
                      }}
                    >
                      imovel imóveis
                    </Nav.Item>

                    <Nav.Item
                      onClick={() => {
                        window.location.assign("/imovel?");
                      }}
                    >
                      Lançamentos
                    </Nav.Item>

                    <Nav.Item
                      onClick={() => {
                        window.location.assign("/imovel?exclusive=true");
                      }}
                    >
                      Exclusividades
                    </Nav.Item>

                    <Nav.Item
                      onClick={() => {
                        window.location.assign(
                          "/imovel?&subtype=Casa&subtype=Apartamento"
                        );
                      }}
                    >
                      Residenciais
                    </Nav.Item>

                    <Nav.Item
                      onClick={() => {
                        window.location.assign(
                          "/imovel?&subtype=Sala%20Comercial&subtype=Terreno%20Comercialo"
                        );
                      }}
                    >
                      Comerciais
                    </Nav.Item>

                    <Nav.Item
                      onClick={() => {
                        window.location.assign("/imovel?&subtype=Terreno");
                      }}
                    >
                      Terrenos
                    </Nav.Item>
                  </Nav.Menu>
                  <Nav.Item href="/vender">Vender</Nav.Item>
                  <Nav.Menu title={"Conteúdos Exclusivos"} className="dropdown">
                    <Nav.Item>TV Nossa Casa</Nav.Item>
                    <Nav.Item>Rádio Nossa Casa</Nav.Item>
                    <Nav.Item>Blog</Nav.Item>
                    <Nav.Item>Instagram</Nav.Item>
                    <Nav.Item>Câmera AO VIVO</Nav.Item>
                  </Nav.Menu>
                  <Nav.Item href="/">
                    <b className="text-[#434343]">Aluguéis</b>
                  </Nav.Item>
                </Nav>
              </Sidenav>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
